import { Crisp } from "crisp-sdk-web";

$(document).on("turbolinks:load", function() {

    if($("meta[name=\'action\']").attr("data-action") == undefined ) return;
    if($("meta[name=\'is_demo_area\']").attr("data-is-demo") == "true" ) return;
    if($("meta[name=\'action\']").attr("data-action") == "home/index" ) return;
    if($("meta[name=\'action\']").attr("data-action") == "home/index" ) return;
    if($("meta[name=\'action\']").attr("data-action") == "sessions/new" ) return;

    Crisp.configure("32ec0457-75b5-465b-8ebf-6b042198ba3b", {
        locale: $("meta[name=\'locale\']").attr("data-language"),
        autoload: false
    });

    Crisp.chat.hide();
    // Doesn't seem to close, we try again after 2 seconds and double check
    // we are in the same place
    var hide_chat = setTimeout(function(){Crisp.chat.hide();}, 2000)

    Crisp.message.onMessageReceived((data) => {
        Crisp.chat.show();
    });
    
    if($("meta[name=\'action\']").attr("data-action") == "monorean_cams/index"){
        Crisp.setTokenId($("#user-email").attr("secret-crisp"));
        Crisp.user.setEmail($("#user-email").text());
    }

    if($("meta[name=\'layout\']").attr("data-name") == "cam_config" ){
        $("#pop-up-chat,#pop-up-chat1,#pop-up-chat2").on('click', function(e){
            e.preventDefault();
            clearTimeout(hide_chat);
            Crisp.chat.show();
            Crisp.chat.open();
        })
    }
});
